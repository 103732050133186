export const baseEndpoints = (config: Partial<State.ConfigType>) => ({
    API_NEWSSTAND: `${config.API_ENV}/newsstand/v2/`,
    API_NEWSSTAND_V3: `${config.API_ENV}/newsstand/v3/`,
    API_BOOKMARKS: `${config.API_ENV}/bookmarks/`,
    API_COMMERCE: `${config.API_ENV}/commerce/v2/`,
    API_COMMERCE_V3: `${config.API_ENV}/commerce/v3/`,
    API_IDENTITY: `${config.API_ENV}/identity/v2/`,
    API_IDENTITY_V3: `${config.API_ENV}/identity/v3/`,
    API_FULFILLMENT: `${config.API_ENV}/fulfillment/v2/`,
    API_CATALOG: `${config.API_ENV}/catalog/v2/`,
    API_ENTITLEMENT: `${config.API_ENV}/entitlement/v2/`,
    API_ENTITLEMENT_V3: `${config.API_ENV}/entitlement/v3/`,
    API_WHITEWALKERS: `${config.API_ENV}/whitewalker/v2/`,
    API_SEARCH_MIDDLEWARE: `${config.API_ENV}/apisearch-middleware/v3/`,
    API_FOLLOWED_PUBLICATION_ARITCLES: `${config.API_ENV}/followed-publication-articles/v3/`,
    API_DEVICE_LIMIT: `${config.API_ENV}/device-limit/v3/`,
    API_SAVED_ARTICLES: `${config.API_ENV}/saved-articles/v3/`,
    API_SAVED_ARTICLES_EVENTS: `${config.API_ENV}/saved-articles-events/v3/`,
    API_USER_DATA: `${config.API_ENV}/user-data/v3/`,
    API_CONSUMPTION_TRACKER: `${config.API_ENV}/consumption-tracker/v3/`,
    API_WISSUE: `${config.API_ENV_WISSUE}/`,
    API_ADMIN: `${config.API_ENV_ADMIN}/api/reader/`,
    API_PROXY: '',
    API_PROXY_NEWSSTAND: 'newsstand/',
    API_PROXY_IDENTITY: 'identity/',
    API_PROXY_COMMERCE: 'commerce/',
    API_PROXY_FULFILLMENT: 'fulfillment/',
    API_PROXY_BOOKMARKS: 'bookmarks/',
    API_PROXY_ENTITLEMENT: 'entitlement/',
    API_PROXY_DEVICE_LIMIT: 'device-limit/',
    API_PROXY_FOLLOWED_PUBLICATION_ARITCLES: 'followed-publication-articles/',
    API_PROXY_CONSUMPTION_TRACKER: 'consumption-tracker/',
    API_PROXY_SAVED_ARTICLES: 'saved-articles/',
    API_PROXY_SAVED_ARTICLES_EVENTS: 'saved-articles-events/',
    API_PROXY_USER_DATA: 'user-data/',
    API_PROXY_SEARCH_MIDDLEWARE: 'apisearch-middleware/',
    API_CMS: '',
    API_STATIC: '',
    API_SUBSCRIPTIONS_MIDDLEWARE: `${config.API_ENV}/subscriptions-middleware/v3/`,
});

export type API_SERVICES_TYPES =
    | 'API_NEWSSTAND'
    | 'API_NEWSSTAND_V3'
    | 'API_BOOKMARKS'
    | 'API_COMMERCE'
    | 'API_COMMERCE_V3'
    | 'API_IDENTITY'
    | 'API_IDENTITY_V3'
    | 'API_DEVICE_LIMIT'
    | 'API_FULFILLMENT'
    | 'API_ENTITLEMENT'
    | 'API_ENTITLEMENT_V3'
    | 'API_WISSUE'
    | 'API_FOLLOWED_PUBLICATION_ARITCLES'
    | 'API_CONSUMPTION_TRACKER'
    | 'API_SAVED_ARTICLES'
    | 'API_SAVED_ARTICLES_EVENTS'
    | 'API_USER_DATA'
    | 'API_SEARCH_MIDDLEWARE'
    | 'API_SUBSCRIPTIONS_MIDDLEWARE';

export type API_TYPES = keyof ReturnType<typeof baseEndpoints>;

export const isAdultContentRating = (contentRating: number) => contentRating >= NO_ADULT_CONTENT_LEGAL;
export const NO_ADULT_CONTENT_MAX_RATING = 15;
export const NO_ADULT_CONTENT_LEGAL = 18;
export const CONTENT_MAX_RATING = 30;
export const DISTRIBUTION_PLATFORM_WWW = 1;

export const SORT_BY_RECENT_ISSUES = 'rank,-relevance,name';
export const ORDER_BY_RECENT_ISSUES = 'desc';

export enum ISSUE_CLASSIFICATION {
    RECENT_ISSUES,
    SPECIAL_ISSUES,
}

export const LIBRARY_SORT_FILTER = {
    ADDED_AT: 'added_at' as State.groupTypes,
    PUBLISH_DATE: 'publish_date' as State.groupTypes,
    TITLE: 'Title' as State.groupTypes,
};

export const DEFAULT_LIBRARY_SORT_FILTER: State.groupTypes = LIBRARY_SORT_FILTER.ADDED_AT;

export enum ENTITLEMENT_STATUS_TYPES {
    // INACTIVE = 0,
    ACTIVE = 1, // called VALID in BE
    REVOKED = 2,
    ARCHIVED = 3,
    // MERGED = 4,
}

export enum ISSUE_STATUS_TYPES {
    // DRAFT = 0,
    PUBLISHED = 1,
    // SCHEDULED = 2,
    ARCHIVED = 3,
    // DISCARDED = 4,
    // RETRACTED = 5,
    // IMPORTING = 6,
    // ON_DECK = 7,
    // READY_FOR_IMPORT = 8,
    // DUPLICATED = 9,
}

export const PAGE_LIMIT = 40;
export const ARTICLE_SEARCH_PAGE_LIMIT = 21;
export const CAROUSSEL_LIMIT = 20; // This seems to not be the default we use
export const TOC_CAROUSSEL_LIMIT = 12;
export const PRODUCT_CAROUSSEL_LIMIT = 10;
export const SEARCH_SUMMARY_LIMIT = 10;
export const BOOKMARKS_PAGE_LIMIT = 24;
export const SAVED_ARTICLES_PAGE_LIMIT = 21;

export const LIBRARY_ISSUES_LIMIT_REQUEST = 80;
export const LIBRARY_NUMBER_OF_PAGES_FIRST_FETCH = 1;

export const BOOKMARKS_LIMIT_REQUEST = 100;
export const BOOKMARKS_PAGE_QUERY_PARAM = 'query[page]';

export const CATEGORIES_LIMIT_REQUEST = 50; // default limit 10
export const EXPLORE_LISTS_LIMIT_REQUEST = 100;

export const MAX_POOLING_STEPS = 20;

export const REFUND_ORDER_SOURCE_TYPE = 3;
export const ORDER_STATUS_FULFILLED = 2;
// TODO: restablish when malintentional user creation is solved
// export const PRIVATE_BODY_FIELDS = ['email', 'password', 'username'];
export const PRIVATE_BODY_FIELDS = ['password'];

export const ZENITH_INTERNAL_CODES = {
    USER_PREFERENCES_NOT_FOUND: '1020100087',
    EXTERNAL_USER_NOT_EXIST: '002.001.013',
    MAX_DEVICES_REACHED: '1020100012',
    USER_ALREADY_EXISTS: '004.008.009',
    EMAIL_NOT_VERIFIED: '1020100066',
    EMPTY_ARTICLE_RECOMMENDATIONS: '1300000004',
};
